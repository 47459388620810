import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "./landings/default/sections/header";
import Footer from "./landings/default/sections/footer";
import { WavyBackground } from "./ui/wavy-background";

const ForgotPassword = function () {
	const { email = "" } = useParams();
	const getInitialEmail = () => {
		if (email.length === 0) {
			return email;
		}
		return typeof atob === "undefined"
			? Buffer.from(email, "base64").toString()
			: atob(email);
	};

	const [data, setData] = useState({
		email: getInitialEmail(),
		password: "",
		repeatPassword: "",
		phase: "",
		error: "",
		code: "",
	});

	const [isVerified] = useState(
		typeof window === "undefined"
			? false
			: window.location.pathname.indexOf("verified") > 0,
	);

	const changeField = (
		fieldName: string,
	): React.ChangeEventHandler<HTMLInputElement> => {
		return ({ target }) => {
			setData({
				...data,
				[fieldName]: target.value,
			});
		};
	};

	const handleSubmitRequest = async () => {
		axios
			.post(
				"/api/v1/authentication/request-change-password",
				{
					email: data.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			)
			.then(() => {
				setData({ ...data, phase: "enter_password" });
			})
			.catch((error2) => {
				setData({ ...data, error: error2.response.data.message });
			});
	};

	const handleChangeRequest = async () => {
		axios
			.post(
				"/api/v1/authentication/confirm-change-password",
				{
					code: data.code,
					password: data.password,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				if (resp.data.status !== "ok") {
					setData({ ...data, error: resp.data.message });
				} else {
					setData({ ...data, error: "", phase: "done" });
				}
			})
			.catch((error2) => {
				setData({ ...data, error: error2.response.data.message });
			});
	};

	const handleSubmit1: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();
		await handleSubmitRequest();
	};

	const handleSubmitChange: React.FormEventHandler<HTMLFormElement> = async (
		e,
	) => {
		e.preventDefault();
		if (data.password !== data.repeatPassword) {
			setData({ ...data, error: "Passwords are not identical" });
		} else {
			await handleChangeRequest();
		}
	};

	const errorMessage = (
		<div
			className="bg-red-100 mb-5 border border-red-400 text-red-700 px-4 py-3 rounded relative"
			role="alert"
		>
			<strong className="font-bold">Holy guacamolly! </strong>
			<span className="block sm:inline">{data.error}</span>
		</div>
	);

	return (
		<div className="landing">
			<Header />
			<div className="bg-grey-100 h-screen">
				<WavyBackground className="max-w-4xl mx-auto pb-40">
					{/* <div className="mb-5">
              Course starts in <DateCountdown dateTo="August 15, 2019 05:00:00 GMT+08:00" />
            </div> */}
					<div className="px-2 md:px-10 align-center justify-center mx-auto h-full flex items-center min-w-[400px] w-full">
						{/* <h1 className="font-hairline mb-6 text-center">Login</h1> */}
						{data.phase === "enter_password" && (
							<form
								className="d-flex flex-column justify-between h-60 w-full"
								onSubmit={handleSubmitChange}
							>
								<div className="border-teal-200 p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg">
									{isVerified && (
										<div
											className="mb-10 bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
											role="alert"
										>
											<div className="flex">
												<div className="py-1">
													<svg
														className="fill-current h-6 w-6 text-teal-500 mr-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
													</svg>
												</div>
												<div>
													<p className="font-bold">Your email was verified</p>
													<p className="text-sm">You may login now</p>
												</div>
											</div>
										</div>
									)}
									<div className="mb-4">
										<label
											htmlFor="email"
											className="font-bold text-grey-darker block mb-2"
										>
											Code from Telegram
										</label>
										<input
											name="code"
											aria-label="code"
											className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
											placeholder="Enter your code from telegram"
											value={data.code}
											onChange={changeField("code")}
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor="email"
											className="font-bold text-grey-darker block mb-2"
										>
											New Password
										</label>
										<input
											name="password"
											aria-label="password"
											type="password"
											className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
											placeholder="Your password"
											value={data.password}
											onChange={changeField("password")}
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor="email"
											className="font-bold text-grey-darker block mb-2"
										>
											Repeat New Password
										</label>
										<input
											name="password"
											aria-label="password"
											type="password"
											className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
											placeholder="Repeat your password"
											value={data.repeatPassword}
											onChange={changeField("repeatPassword")}
										/>
									</div>
									<div className="flex items-center justify-between">
										{data.error ? errorMessage : ""}
									</div>
									<div className="flex items-center justify-between">
										<button
											type="submit"
											className="bg-teal-700 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded"
										>
											Send
										</button>

										<Link
											className="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-700 float-right"
											to="/login"
										>
											Login
										</Link>
									</div>
								</div>
							</form>
						)}
						{data.phase === "done" && (
							<div className="d-flex flex-column justify-between h-60 w-full">
								<div className="border-teal-200 p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg">
									<div className="flex items-center justify-between">
										<div
											className="bg-green-100 mb-5 border border-green-400 text-green-700 px-4 py-3 rounded relative"
											role="alert"
										>
											<strong className="font-bold">Wow! </strong>
											<span className="block sm:inline">
												Password has been changed
											</span>
										</div>
									</div>
									<div className="flex items-center justify-between">
										<Link
											className="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-700 float-right"
											to="/login"
										>
											Login
										</Link>
									</div>
								</div>
							</div>
						)}
						{data.phase === "" && (
							<form
								className="d-flex flex-column justify-between h-60 w-full"
								onSubmit={handleSubmit1}
							>
								<div className="border-teal-200 p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg">
									{isVerified && (
										<div
											className="mb-10 bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
											role="alert"
										>
											<div className="flex">
												<div className="py-1">
													<svg
														className="fill-current h-6 w-6 text-teal-500 mr-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
													</svg>
												</div>
												<div>
													<p className="font-bold">Your email was verified</p>
													<p className="text-sm">You may login now</p>
												</div>
											</div>
										</div>
									)}
									<div className="mb-4">
										<label
											htmlFor="email"
											className="block text-sm font-medium text-gray-700"
										>
											Email address
										</label>
										<input
											name="email"
											aria-label="email"
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
											placeholder="Your Username"
											value={data.email}
											onChange={changeField("email")}
										/>
									</div>

									<div className="flex items-center justify-between">
										{data.error ? errorMessage : ""}
									</div>
									<div className="flex items-center justify-between">
										{" "}
										<div>
											<Link
												className="font-medium text-teal-600 hover:text-teal-500"
												to="/login"
											>
												Go back
											</Link>
										</div>
										<div>
											<button
												type="submit"
												className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
											>
												Change Password
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
						{/* <div className="text-center">
                <p className="text-grey-900 text-sm">
                  Don&apos;t have an account?{' '}
                  <a href="#" className="no-underline text-blue-900 font-bold">
                    Create an Account
                  </a>
                  .
                </p>
              </div> */}
					</div>
				</WavyBackground>
			</div>

			<Footer />
		</div>
	);
};

export default ForgotPassword;
