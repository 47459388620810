import React from 'react'
import { Link } from 'react-router-dom'
import { getLanguage } from '../../config/i18n'

const CourseDisclaimer = () => {
  const courses = [
    {
      id: 'blockchain-dev',
      title: 'Blockchain Development',
      description:
        'Build secure smart contracts with Rust and Solidity. Explore zero-knowledge proofs.',
      duration: '32 weeks',
      tag: 'Web3',
      image: '/images/bg-blockchain.webp',
      startDate: 'February 2025',
      gradient: 'from-purple-500/10 to-pink-500/10',
      tagColor: 'text-purple-500 dark:text-purple-400',
      borderColor: 'border-purple-200 dark:border-purple-800',
      icon: (
        <svg
          className="w-3.5 h-3.5 shrink-0"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          role="img"
          aria-labelledby="blockchainIcon"
        >
          <title id="blockchainIcon">Blockchain course icon</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
          />
        </svg>
      )
    },
    {
      id: 'ai-hustler',
      title: 'AI Hustler',
      description:
        'Launch MVPs using ChatGPT, Claude, and Midjourney for rapid product development.',
      duration: '24 weeks',
      tag: 'AI Tools',
      image: '/images/landing/knowledge.webp',
      startDate: 'March 2025',
      gradient: 'from-pink-500/10 to-red-500/10',
      tagColor: 'text-pink-500 dark:text-pink-400',
      borderColor: 'border-pink-200 dark:border-pink-800',
      icon: (
        <svg
          className="w-3.5 h-3.5 shrink-0"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          role="img"
          aria-labelledby="aiIcon"
        >
          <title id="aiIcon">AI course icon</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      )
    },
    {
      id: 'fullstack-2025',
      title: 'Fullstack 2025',
      description:
        'Master microservices architecture with NestJS, Docker, and cloud-native practices.',
      duration: '24 weeks',
      tag: 'Microservices',
      image: '/images/bg.webp',
      startDate: 'May 2025',
      gradient: 'from-blue-500/10 to-purple-500/10',
      tagColor: 'text-blue-500 dark:text-blue-400',
      borderColor: 'border-blue-200 dark:border-blue-800',
      icon: (
        <svg
          className="w-3.5 h-3.5 shrink-0"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          role="img"
          aria-labelledby="fullstackIcon"
        >
          <title id="fullstackIcon">Fullstack course icon</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          />
        </svg>
      )
    }
  ]

  return (
    <div className="mb-8">
      <div className="flex items-center gap-2 mb-4">
        <div className="text-gray-400 dark:text-gray-500">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="img"
            aria-labelledby="newCoursesIcon"
          >
            <title id="newCoursesIcon">New courses available</title>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
            />
          </svg>
        </div>
        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
          New Courses Available
        </h2>
      </div>

      <div className="grid gap-4 md:grid-cols-3">
        {courses.map((course) => (
          <Link
            key={course.id}
            to={`/${getLanguage()}/courses`}
            className={`relative group bg-white dark:bg-gray-800 rounded-lg overflow-hidden border ${course.borderColor} hover:shadow-lg transition-shadow`}
          >
            <div className="relative h-32 bg-gray-100 dark:bg-gray-800 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent z-[5]" />
              <img
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                src={course.image}
                alt={`${course.title} course thumbnail`}
                loading="lazy"
              />
            </div>

            <div className="absolute top-2 right-2 z-[10] px-1.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-700 dark:bg-emerald-900/50 dark:text-emerald-300">
              {course.startDate}
            </div>

            <div className="p-3 space-y-2">
              <div className="flex justify-between items-start gap-2 h-10">
                <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 line-clamp-2">
                  {course.title}
                </h3>
              </div>

              <p className="text-xs text-gray-600 dark:text-gray-300 line-clamp-2">
                {course.description}
              </p>

              <div className="border-t border-gray-100 dark:border-gray-700 pt-2">
                <div className={`flex items-center gap-1.5 ${course.tagColor}`}>
                  {course.icon}
                  <span className="text-xs font-medium">{course.duration}</span>
                  <span className="mx-2">•</span>
                  <span className="text-xs font-medium">{course.tag}</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default CourseDisclaimer
