import React, { useEffect, useState, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism } from 'react-syntax-highlighter'
import type { SyntaxHighlighterProps } from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Clipboard } from 'lucide-react'
import type { ComponentPropsWithoutRef, HTMLAttributes, ReactNode, CSSProperties, ReactElement } from 'react'
import { Editor } from '../common/text-editor/editor'
import Playground from 'javascript-playgrounds'
import type { Components } from 'react-markdown'

interface CustomCSSProperties extends CSSProperties {
  '--bg-color'?: string;
  '--fg-color'?: string;
  '--editor-bg-color'?: string;
  '--editor-fg-color'?: string;
  '--syntax-keyword-color'?: string;
  '--syntax-string-color'?: string;
  '--syntax-comment-color'?: string;
}

const PrismHighlighter = Prism as React.ComponentType<SyntaxHighlighterProps>

interface PreProps extends HTMLAttributes<HTMLPreElement> {
  children: ReactNode
}

interface CodeElementProps {
  children?: string
}

const lightStyle = {
  'pre[class*="language-"]': {
    background: '#ffffff',
    margin: 0,
    padding: '1rem',
    fontSize: '0.9rem',
    color: '#000000',
    borderRadius: '0.5rem',
    lineHeight: '1.5'
  },
  'code[class*="language-"]': {
    color: '#000000',
    fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace'
  },
  'token.comment': {
    color: '#008000'  // Green comments
  },
  'token.punctuation': {
    color: '#000000'  // Black punctuation
  },
  'token.tag': {
    color: '#800000'  // Dark red for tags
  },
  'token.attr-name': {
    color: '#e50000'  // Bright red for attributes
  },
  'token.attr-value': {
    color: '#0000ff'  // Blue for values
  },
  'token.entity': {
    color: '#800000'
  }
}

const Pre = ({ children, ...props }: PreProps) => {
  const [copied, setCopied] = React.useState(false)
  const textContent = React.Children.toArray(children)
    .map(child => {
      if (typeof child === 'string') return child
      if (React.isValidElement<CodeElementProps>(child)) {
        return child.props.children || ''
      }
      return ''
    })
    .join('')

  const handleCopy = () => {
    navigator.clipboard.writeText(textContent)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="group/pre relative">
      <pre {...props} className="!pl-4">
        {children}
      </pre>
      <div className="absolute inset-0 bg-black opacity-0 group-hover/pre:opacity-30 transition-opacity duration-200 z-10 pointer-events-none" />
      <button
        type="button"
        onClick={handleCopy}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-lg bg-gray-100 dark:bg-gray-700 opacity-0 group-hover/pre:opacity-90 transition-opacity duration-200 hover:bg-gray-200 dark:hover:bg-gray-600 z-20"
        aria-label="Copy code"
      >
        <Clipboard size={24} className={copied ? "text-green-500" : "dark:text-gray-300 text-gray-600"} />
      </button>
    </div>
  )
}

interface CodeProps extends ComponentPropsWithoutRef<'code'> {
  inline?: boolean
}

const Code: React.FC<CodeProps> = ({ className, children, inline }) => {
  const match = /language-(\w+)/.exec(className || '')

  if (inline) {
    return (
      <code className="px-1.5 py-0.5 rounded bg-gray-100 dark:bg-transparent font-mono text-sm text-gray-800 dark:text-gray-200">
        {children}
      </code>
    )
  }

  if (!match) {
    return (
      <code className="px-1.5 py-0.5 rounded bg-gray-100 dark:bg-transparent font-mono text-sm text-gray-800 dark:text-gray-200">
        {children}
      </code>
    )
  }

  const content = String(children).replace(/\n$/, '')

  return (
    <div className="rounded-lg overflow-hidden">
      <div className="dark:hidden">
        <div style={lightStyle['pre[class*="language-"]']}>
          <PrismHighlighter
            language={match[1]}
            style={lightStyle}
            PreTag="div"
            className="!bg-transparent"
          >
            {content}
          </PrismHighlighter>
        </div>
      </div>
      <div className="hidden dark:block p-4">
        <PrismHighlighter
          language={match[1]}
          style={{
            'pre[class*="language-"]': {
              background: 'transparent',
              color: '#e1e1e1',
              lineHeight: '1.5',
              fontSize: '0.9rem',
              padding: '0'
            },
            ':not(pre) > code[class*="language-"]': {
              background: 'transparent'
            },
            'code[class*="language-"]': {
              background: 'transparent',
              color: '#ffffff'
            },
            'token.comment': {
              color: '#608b4e'  // Darker green for better contrast
            },
            'token.punctuation': {
              color: '#808080'  // Mid gray
            },
            'token.tag': {
              color: '#f92672'  // Bright pink for tags
            },
            'token.attr-name': {
              color: '#66d9ef'  // Bright blue for attributes
            },
            'token.attr-value': {
              color: '#a6e22e'  // Bright green for values
            },
            'token.entity': {
              color: '#f92672'
            }
          }}
          PreTag="div"
          className="!bg-transparent"
        >
          {content}
        </PrismHighlighter>
      </div>
    </div>
  )
}

const calculateHeight = (code: string): number => {
  const lineCount = code.split('\n').length;
  const lineHeight = 22; // Approximate height per line in pixels
  return (lineCount + 5) * lineHeight; // Add 5 extra lines
}

const PlaygroundWrapper: React.FC<{ code: string; type: string }> = ({ code, type }) => {
  const minHeight = calculateHeight(code);

  return (
    <div className="my-4">
      <div className="dark:invert" style={{ minHeight }}>
        <Playground
          code={code.trim()}
          preset={type}
          className="w-full h-full"
          style={{
            '--bg-color': '#ffffff',
            '--fg-color': '#000000',
            '--editor-bg-color': '#ffffff',
            '--editor-fg-color': '#000000',
            '--syntax-keyword-color': '#007bff',
            '--syntax-string-color': '#28a745',
            '--syntax-comment-color': '#6c757d',
          } as CustomCSSProperties}
        />
      </div>
    </div>
  );
};

const processMarkdown = (markdown: string): ReactElement[] => {
  const parts = markdown.split(/(<playground[^>]*>[\s\S]*?<\/playground>)/)
  let nonPlaygroundContent = ''
  const elements: ReactElement[] = []

  parts.forEach((part, index) => {
    const match = part.match(/<playground type="([^"]+)">([\s\S]*?)<\/playground>/)
    if (match) {
      if (nonPlaygroundContent) {
        elements.push(
          <ReactMarkdown key={`md-${elements.length}`} components={components}>
            {nonPlaygroundContent}
          </ReactMarkdown>
        )
        nonPlaygroundContent = ''
      }

      const [, type, code] = match
      elements.push(
        <PlaygroundWrapper
          key={`pg-${elements.length}`}
          code={code}
          type={type}
        />
      )
    } else {
      nonPlaygroundContent += part
    }
  })

  if (nonPlaygroundContent) {
    elements.push(
      <ReactMarkdown key={`md-${elements.length}`} components={components}>
        {nonPlaygroundContent}
      </ReactMarkdown>
    )
  }

  return elements
}

interface LessonContentProps {
  adminEditMode: boolean;
  description: string;
  onSetDescription: (description: string) => void;
  lessonId?: string;
}

const components: Partial<Components> = {
  code: Code as Components['code'],
  pre: Pre as Components['pre']
}

class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    console.error('LessonContent Error:', error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 text-red-600 bg-red-50 rounded-lg">
          An error occurred while rendering the lesson content. Please try refreshing the page.
        </div>
      )
    }

    return this.props.children
  }
}

const LessonContentInner: React.FC<LessonContentProps> = ({ adminEditMode, description, onSetDescription, lessonId }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentMode, setCurrentMode] = useState(adminEditMode);
  const viewRecorded = useRef(false);

  useEffect(() => {
    const recordView = async () => {
      if (viewRecorded.current) {
        console.log('View already recorded');
        return;
      }
      
      if (!lessonId) {
        return;
      }
      console.log('Recording view for lesson:', lessonId);
      
      try {
        const response = await fetch(`/api/v1/courses/lesson/${lessonId}/increment-views`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        
        if (!response.ok) {
          const error = await response.json();
          console.error('Failed to record view:', error);
          return;
        }
        
        const result = await response.json();
        console.log('View recorded successfully:', result);
        viewRecorded.current = true;
      } catch (error) {
        console.error('Failed to record lesson view:', error);
      }
    };

    recordView();
  }, [lessonId]);

  // Handle mode transitions
  useEffect(() => {
    if (currentMode !== adminEditMode) {
      setIsTransitioning(true);
      // Small delay to ensure clean unmount/mount
      const timer = setTimeout(() => {
        setCurrentMode(adminEditMode);
        setIsTransitioning(false);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [adminEditMode, currentMode]);

  if (isTransitioning) {
    return (
      <div className="mt-8 animate-pulse">
        <div className="h-96 bg-gray-100 dark:bg-gray-800 rounded-lg" />
      </div>
    );
  }

  return (
    <div className="mt-8">
      {currentMode ? (
        <div key="editor-container" className="transition-opacity duration-200">
          <Editor 
            value={description} 
            onChange={onSetDescription}
            className="w-full"
          />
        </div>
      ) : (
        <div key="markdown-container" className="transition-opacity duration-200 prose prose-lg max-w-none dark:prose-invert prose-headings:font-medium prose-a:text-blue-600 dark:prose-a:text-blue-400 prose-pre:p-0 prose-pre:m-0 prose-img:rounded-lg">
          {processMarkdown(description)}
        </div>
      )}
    </div>
  );
}

const LessonContent: React.FC<LessonContentProps> = (props) => (
  <ErrorBoundary>
    <LessonContentInner {...props} />
  </ErrorBoundary>
);

export default React.memo(LessonContent);
