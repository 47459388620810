import { type FormEventHandler, useState, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { login } from '@data/reducers/authentication'
import { useAppDispatch, useAppSelector } from '@data'
import { User, Lock, Mail, Eye, EyeOff } from 'lucide-react'

const LoginForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const error = useAppSelector((s) => s.authentication.error)
  const { email = '' } = useParams<{ email: string }>()
  const getInitialEmail = () => {
    if (email.length === 0) {
      return email
    }
    return typeof atob === 'undefined' ? Buffer.from(email, 'base64').toString() : atob(email)
  }
  const [data, setData] = useState({
    email: getInitialEmail(),
    password: ''
  })
  const [showPassword, setShowPassword] = useState(false)

  const [isVerified] = useState(
    typeof window === 'undefined' ? false : window.location.pathname.indexOf('verified') > 0
  )

  const changeField = (fieldName: string): React.ChangeEventHandler<HTMLInputElement> => {
    return ({ target }) => {
      setData({
        ...data,
        [fieldName]: target.value
      })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const loginUser = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()
      try {
        await dispatch(login(data)).unwrap()
        // If login is successful, navigate to dashboard or home page
        navigate('/dashboard')
      } catch (err) {
        // Error is handled by the reducer and will be available in the 'error' state
        console.error('Login failed:', err)
      }
    },
    [data, dispatch, navigate]
  )

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
        <div className="px-6 py-8 sm:px-10">
          <div className="mb-6 text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Welcome back</h2>
            <p className="mt-2 text-sm text-gray-600">
              Sign in to your account
            </p>
          </div>

          {isVerified && (
            <div className="mb-6 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded" role="alert">
              <p className="font-bold">Email verified</p>
              <p>You can now log in to your account</p>
            </div>
          )}

          <form className="space-y-6" onSubmit={loginUser}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={data.email}
                  onChange={changeField('email')}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  value={data.password}
                  onChange={changeField('password')}
                  className="block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                  placeholder="••••••••"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <Eye className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link className="font-medium text-teal-600 hover:text-teal-500" to="/forgot-password">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Sign in
              </button>
            </div>
          </form>

          {error && (
            <div className="mt-6 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
              <p className="font-bold">Error</p>
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginForm
