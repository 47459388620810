import { useNavigate } from 'react-router-dom'
import Header from './landings/default/sections/header'
import Footer from './landings/default/sections/footer'
import LoginForm from './login-form'
import { WavyBackground } from './ui/wavy-background'

const LoginPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <WavyBackground className="flex-grow">
        <div className="relative z-10 flex justify-center items-center min-h-[calc(100vh-64px-80px)]  sm:max-w-lg">
          <div className="w-full max-w-md px-4 py-8">
            <LoginForm />
          </div>
        </div>
      </WavyBackground>
      <Footer />
    </div>
  )
}

export default LoginPage
