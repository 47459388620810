import type { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { getLanguage } from '../../config/i18n'
import type { Lesson } from '../../interfaces/mongoose.gen'

interface NavigationButtonsProps {
  courseId: string
  weekId: string
  lessons: Lesson[]
  currentLessonId: string
  onNavigate: () => void
}

const NavigationButtons = ({ courseId, weekId, lessons, currentLessonId, onNavigate }: NavigationButtonsProps): ReactElement => {
  const currentIndex = lessons.findIndex((lesson) => lesson._id === currentLessonId)
  const prevLesson = lessons[currentIndex - 1]
  const nextLesson = lessons[currentIndex + 1]

  return (
    <>
      {prevLesson && (
        <Link
          to={`/${getLanguage()}/course/${courseId}/${weekId}/${prevLesson._id}`}
          onClick={onNavigate}
          className="group fixed left-4 top-1/2 -translate-y-1/2 hidden md:flex items-center justify-center w-12 h-12 rounded-full bg-black/20 hover:bg-black/40 dark:bg-white/20 dark:hover:bg-white/40 text-white transition-all"
          aria-label={`Go to previous lesson: ${prevLesson.name}`}
        >
          <svg 
            className="w-6 h-6 transform group-hover:-translate-x-0.5 transition-transform" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
          
          {/* Tooltip */}
          <div className="absolute left-full ml-2 px-2 py-1 bg-gray-900 dark:bg-gray-700 text-white text-sm rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
            <div className="absolute top-1/2 -translate-y-1/2 -left-1 w-2 h-2 bg-gray-900 dark:bg-gray-700 transform rotate-45" />
            {prevLesson.name}
          </div>
        </Link>
      )}
      
      {nextLesson && (
        <Link
          to={`/${getLanguage()}/course/${courseId}/${weekId}/${nextLesson._id}`}
          onClick={onNavigate}
          className="group fixed right-4 top-1/2 -translate-y-1/2 hidden md:flex items-center justify-center w-12 h-12 rounded-full bg-black/20 hover:bg-black/40 dark:bg-white/20 dark:hover:bg-white/40 text-white transition-all"
          aria-label={`Go to next lesson: ${nextLesson.name}`}
        >
          <svg 
            className="w-6 h-6 transform group-hover:translate-x-0.5 transition-transform" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>

          {/* Tooltip */}
          <div className="absolute right-full mr-2 px-2 py-1 bg-gray-900 dark:bg-gray-700 text-white text-sm rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
            <div className="absolute top-1/2 -translate-y-1/2 -right-1 w-2 h-2 bg-gray-900 dark:bg-gray-700 transform rotate-45" />
            {nextLesson.name}
          </div>
        </Link>
      )}

      {/* Mobile Navigation */}
      <div className="flex justify-between gap-4 my-4 md:hidden">
        {prevLesson && (
          <Link
            to={`/${getLanguage()}/course/${courseId}/${weekId}/${prevLesson._id}`}
            onClick={onNavigate}
            className="group relative flex items-center gap-2 px-4 py-2 rounded-lg bg-black/10 hover:bg-black/20 dark:bg-white/10 dark:hover:bg-white/20 text-gray-700 dark:text-gray-200 transition-colors"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
            <span className="text-sm font-medium">Previous</span>

            {/* Mobile Tooltip */}
            <div className="absolute left-0 -top-8 w-full px-2 py-1 bg-gray-900 dark:bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none text-center">
              {prevLesson.name}
            </div>
          </Link>
        )}
        
        {nextLesson && (
          <Link
            to={`/${getLanguage()}/course/${courseId}/${weekId}/${nextLesson._id}`}
            onClick={onNavigate}
            className="group relative flex items-center gap-2 px-4 py-2 rounded-lg bg-black/10 hover:bg-black/20 dark:bg-white/10 dark:hover:bg-white/20 text-gray-700 dark:text-gray-200 transition-colors ml-auto"
          >
            <span className="text-sm font-medium">Next</span>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>

            {/* Mobile Tooltip */}
            <div className="absolute right-0 -top-8 w-full px-2 py-1 bg-gray-900 dark:bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none text-center">
              {nextLesson.name}
            </div>
          </Link>
        )}
      </div>
    </>
  )
}

export default NavigationButtons
