import { useState, useLayoutEffect, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import 'react-mde/lib/styles/css/react-mde-all.css'

import { useAppSelector } from '@data'
import { getLanguage } from '../../config/i18n'
import type { HomeWorkQuestions } from '../../interfaces/mongoose.gen'
import Head from '../head'
import api from '../../services/api'

interface ValidationState {
  [key: string]: boolean
}

interface AnswersState {
  [key: string]: string
}

const Interview = () => {
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const [questions, setQuestions] = useState<HomeWorkQuestions[]>([])
  const [validatation, setValidation] = useState<ValidationState>({})
  const [answers, setAnswers] = useState<AnswersState>({})
  const [isShaking, setIsShaking] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { weekId, id: courseId } = useParams<{ weekId: string; id: string }>()

  const course = useAppSelector((s) => s.courses.list.find((it) => it?.courseId === courseId))
  const week = useAppSelector((s) =>
    (s.courses.list.find((it) => it?.courseId === courseId)?.weeks ?? []).find(
      (it) => it.weekId === weekId
    )
  )
  const weekIndex = useAppSelector((s) =>
    (s.courses.list.find((it) => it?.courseId === courseId)?.weeks ?? []).findIndex(
      (it) => it.weekId === weekId
    )
  )

  useEffect(() => {
    api.post('/courses/interview', { lessons: week?.lessonIds ?? [] }).then(({ data }) => {
      setQuestions(data.data)
    })
  }, [week])

  useLayoutEffect(() => {
    const element = document.getElementById('main-container')

    if (!isLoaded && element) {
      try {
        element.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } catch (error) {
        element.scrollTo(0, 0)
      }
      setLoaded(true)
    }
  }, [isLoaded])

  const scrollToFirstIncorrectQuestion = (incorrectAnswers: string[]) => {
    if (incorrectAnswers.length > 0) {
      const firstIncorrectQuestionId = incorrectAnswers[0]
      const element = document.querySelector(`[data-question-id="${firstIncorrectQuestionId}"]`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  const validateAnswer = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      const response = await api.post('/courses/interview/submit', {
        answers,
        weekIndex
      })

      if (response.data.success) {
        setIsSuccess(true)
        const element = document.getElementById('main-container')
        if (element) {
          try {
            element.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          } catch (error) {
            element.scrollTo(0, 0)
          }
        }
        toastr.success('🎉 Congratulations!', response.data.message)
      } else {
        setIsShaking(true)
        setTimeout(() => setIsShaking(false), 650) // Duration matches CSS animation
        
        // Mark incorrect answers
        const incorrectAnswers = response.data.incorrectAnswers || []
        const newValidation = incorrectAnswers.reduce((acc: ValidationState, questionId: string) => {
          acc[questionId] = true
          return acc
        }, {})
        setValidation(newValidation)
        
        // Scroll to first incorrect answer
        scrollToFirstIncorrectQuestion(incorrectAnswers)
        
        toastr.error('Incorrect Answers', response.data.message)
      }
    } catch (error) {
      toastr.error('Error', 'Failed to submit answers. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleAnswerChange = (questionId: string, value: string): void => {
    setValidation((prev) => Object.assign({}, prev, { [questionId]: false }))
    setAnswers((prev) => Object.assign({}, prev, { [questionId]: value }))
  }

  const answeredQuestions = Object.keys(answers).length
  const progressPercentage = (answeredQuestions / questions.length) * 100

  return (
    <div className="max-w-4xl mx-auto px-4 py-6">
      <Head title="Skillcrucial - Interview" />

      {/* Success Banner */}
      {isSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 rounded-lg p-8 max-w-lg w-full mx-4 text-center border-2 border-teal-500 shadow-2xl animate-bounce-once">
            <div className="text-4xl mb-4">🎉 🌟 🚀</div>
            <h2 className="text-3xl font-bold text-teal-400 mb-2">You're Awesome!</h2>
            <p className="text-xl text-gray-200 mb-2">
              You've crushed this interview like a pro!
            </p>
            <p className="text-gray-400 mb-8 text-lg">
              Time to celebrate with a victory dance! 💃 🕺
            </p>
            <Link
              to={`/${getLanguage()}/course/${courseId}/${weekId}`}
              className="inline-flex items-center px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors duration-200 group"
            >
              <span className="group-hover:scale-110 transition-transform duration-200">
                🎯 Back to {week?.name || 'Week'} 🎯
              </span>
            </Link>
          </div>
        </div>
      )}

      {/* Breadcrumb Navigation */}
      <nav className="mb-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-sm">
          <li>
            <Link to="/" className="text-teal-400 hover:text-teal-300 transition-colors">
              <HomeIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </li>
          <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          <li>
            <Link
              to={`/${getLanguage()}/course/${courseId}`}
              className="text-teal-400 hover:text-teal-300 transition-colors"
            >
              {course?.name}
            </Link>
          </li>
          <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          <li>
            <Link
              to={`/${getLanguage()}/course/${courseId}/${weekId}`}
              className="text-teal-400 hover:text-teal-300 transition-colors"
            >
              {week?.name}
            </Link>
          </li>
          <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          <li>
            <span className="text-gray-400">Interview Questions</span>
          </li>
        </ol>
      </nav>

      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium text-gray-300">Progress</span>
          <span className="text-sm font-medium text-gray-300">
            {answeredQuestions} of {questions.length} questions answered
          </span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div
            className="bg-teal-500 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>

      {/* Questions */}
      <div className="relative space-y-0 border-l-2 border-gray-600/30 ml-6 pl-10">
        {questions.map((question, index) => {
          const questionId = question._id
          if (!questionId) return null

          const isAnswered = !!answers[questionId]
          const isIncorrect = validatation[questionId]

          return (
            <div
              key={questionId}
              data-question-id={questionId}
              className={classNames(
                'rounded-lg shadow-lg p-6 bg-gray-800 mb-6 relative',
                {
                  'bg-red-900/20': isIncorrect
                }
              )}
            >
              {/* Question Number Circle */}
              <div className={classNames(
                'absolute -left-[42px] w-6 h-6 rounded-full flex items-center justify-center text-sm font-medium -translate-x-1/2 transition-colors duration-200',
                {
                  'bg-gray-800 text-red-400 border border-red-400': isIncorrect,
                  'bg-gray-800 text-teal-400 border border-teal-400': isAnswered && !isIncorrect,
                  'bg-gray-800 text-gray-400 border border-gray-600/30': !isAnswered
                }
              )}>
                {index + 1}
              </div>

              {/* Question Content */}
              <div className="flex-1">
                <h2
                  className={classNames('text-lg font-medium mb-4', {
                    'text-red-400': isIncorrect,
                    'text-gray-100': !isIncorrect
                  })}
                >
                  <ReactMarkdown>{question.question}</ReactMarkdown>
                </h2>

                {question.type === 'text' && (
                  <input
                    className={classNames(
                      'w-full px-4 py-2 rounded-md border bg-gray-900 text-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200',
                      {
                        'border-red-500/50 focus:border-red-500 focus:ring-red-500': isIncorrect,
                        'border-gray-700 focus:border-teal-500 focus:ring-teal-500': !isIncorrect
                      }
                    )}
                    placeholder="Type your answer here..."
                    value={answers[questionId] || ''}
                    onChange={(e) => handleAnswerChange(questionId, e.target.value)}
                  />
                )}

                {question.type === 'single' && (
                  <div className="flex flex-wrap gap-3">
                    {question.options.map((option) => {
                      const optionId = option._id
                      const optionText = option.text

                      if (!optionId || typeof optionText !== 'string') return null

                      return (
                        <button
                          key={optionId}
                          type="button"
                          onClick={() => handleAnswerChange(questionId, optionText)}
                          className={classNames(
                            'px-4 py-2 rounded-md font-medium transition-all duration-200',
                            {
                              'bg-teal-600 text-white shadow-md hover:bg-teal-700':
                                answers[questionId] === optionText,
                              'bg-gray-700 text-gray-300 hover:bg-gray-600':
                                answers[questionId] !== optionText
                            }
                          )}
                        >
                          {optionText}
                        </button>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>

      {/* Submit Button */}
      <div className="mt-8 flex justify-end">
        <button
          type="button"
          onClick={validateAnswer}
          disabled={answeredQuestions !== questions.length || isSubmitting}
          className={classNames(
            'px-6 py-3 rounded-lg font-medium text-white shadow-lg transition-all duration-200',
            {
              'bg-red-600 hover:bg-red-700': isShaking,
              'bg-teal-600 hover:bg-teal-700': answeredQuestions === questions.length && !isSubmitting && !isShaking,
              'bg-gray-600 cursor-not-allowed': answeredQuestions !== questions.length || isSubmitting,
              'animate-shake': isShaking
            }
          )}
        >
          {isSubmitting ? (
            'Submitting...'
          ) : answeredQuestions === questions.length ? (
            'Submit Answers'
          ) : (
            'Answer all questions to submit'
          )}
        </button>
      </div>
    </div>
  )
}

export default Interview
